import { ProgramCard } from "../../pages/shared/EBursary/containers";
import styles from "./styles.module.css";

export const ApplicationTabs = ({ items }) => {
	return (
		<div className={styles.applicationTabs}>
			<div className={styles.application_section_header}>
				<h2 className="pb-1">Key Features</h2>
			</div>
			<div className={styles.application_tab_tabs}>
				{items?.map((item, idx) => (
					<ProgramCard key={idx} {...item} />
				))}
			</div>
		</div>
	);
};
