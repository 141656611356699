import { lazy } from "react";
import {
	SharedRouter,
	SundryRouter,
	PGRouter,
	PreviewRouter
} from "../routers/index";
const LazyLandingPage = lazy(() =>
	import("../../pages/shared/LandingPage/landingPage")
);
const LazyEBursaryPage = lazy(() =>
	import("../../pages/shared/EBursary/eBursary")
);
const LazyECertificate = lazy(() =>
	import("../../pages/shared/ECertificate/eCertificate.js")
);
const LazyLogin = lazy(() => import("../../pages/shared/Login/login"));
const LazyForgotPassword = lazy(() =>
	import("../../pages/shared/ForgotPassword")
);
const LazyChangeEmail = lazy(() => import("../../pages/shared/ChangeEmail"));
const LazyResetPassword = lazy(() =>
	import("../../pages/shared/ResetPassword")
);
const LazyOtpVerfication = lazy(() =>
	import("../../pages/shared/OtpVerfication")
);
const LazyImpersonationLogin = lazy(() =>
	import("../../pages/shared/ImpersonationLogin")
);
const LazyVerifyAccount = lazy(() =>
	import("../../pages/shared/VerifyAccount")
);
const LazyCreateProfile = lazy(() =>
	import("../../pages/shared/CreateProfile/createProfille")
);
const LazyVerifyConfirmation = lazy(() =>
	import("../../pages/shared/VerifyConfirmation")
);
const LazySundrySelection = lazy(() =>
	import("../../pages/shared/SundryPayments/pages/SundrySelection")
);
const LazyTranscript = lazy(() =>
	import("../../pages/shared/Transcript/transcript")
);
const LazySundryInvoice = lazy(() =>
	import(
		"../../pages/shared/SundryPayments/pages/SundryInvoice/sundryInvoice"
	)
);
const LazyPGSchoolFees = lazy(() =>
	import("../../pages/shared/PG/pages/SchoolFees/schoolFees")
);
const LazyPGInvoice = lazy(() =>
	import("../../pages/shared/PG/pages/SchoolFees/pgInvoice")
);
const LazyPGSundrySelection = lazy(() =>
	import("../../pages/shared/PG/pages/PGSundrySelection")
);
const LazyPGLogin = lazy(() =>
	import("../../pages/shared/PGApplication/pgLogin")
);
const LazyPGApplication = lazy(() =>
	import("../../pages/shared/PGApplication/pgApplication")
);
const LazyPGReprintLogin = lazy(() =>
	import("../../pages/shared/PGApplication/pgReprintLogin")
);
const LazyPGApplicationDetails = lazy(() =>
	import(
		"../../pages/shared/PGApplication/PGApplicationDetails/pgApplicationDetails"
	)
);
const LazyPGReferenceForm = lazy(() =>
	import("../../pages/shared/PGApplication/pgReferenceForm")
);
const LazyProspectiveStudents = lazy(() =>
	import("../../pages/shared/ProspectiveStudents/prospectiveStudents")
);
const LazyAdmissionStatusInvoice = lazy(() =>
	import(
		"../../pages/shared/ProspectiveStudents/components/AdmissionStatusInvoice/admissionStatusInvoice"
	)
);
const LazyGenerateJambPaymentInvoice = lazy(() =>
	import(
		"../../pages/shared/JambStudents/pages/GenerateJambPaymentInvoice/generateJambPaymentInvoice"
	)
);
const LazyJambStudentsInvoice = lazy(() =>
	import("../../pages/shared/JambStudents/pages/Invoice/jambStudentsInvoice")
);
const LazyGenerateOtherStudentsPaymentInvoice = lazy(() =>
	import(
		"../../pages/shared/OtherStudents/pages/GenerateOtherStudentsPaymentInvoice/generateOtherStudentsPaymentInvoice"
	)
);
const LazyOtherStudentsInvoice = lazy(() =>
	import(
		"../../pages/shared/OtherStudents/pages/Invoice/otherStudentsInvoice"
	)
);
const LazyGenerateJupebStudentsPaymentInvoice = lazy(() =>
	import(
		"../../pages/shared/JupebStudents/pages/GenerateJupebStudentsPaymentInvoice/generateJupebStudentsPaymentInvoice"
	)
);
const LazyJupebStudentsInvoice = lazy(() =>
	import(
		"../../pages/shared/JupebStudents/pages/Invoice/JupebStudentsInvoice"
	)
);
const LazyJupebLogin = lazy(() =>
	import("../../pages/shared/JupebApplication/jupebLogin")
);
const LazyJupebApplication = lazy(() =>
	import("../../pages/shared/JupebApplication/jupebApplication")
);
const LazyJupebPreview = lazy(() =>
	import("../../pages/shared/JupebApplication/jupebPreview")
);
const LazyPutmeLogin = lazy(() =>
	import("../../pages/shared/PUTMEApplication/putmeLogin")
);
const LazyPutmeApplication = lazy(() =>
	import("../../pages/shared/PUTMEApplication/putmeApplication")
);
const LazyPutmePreview = lazy(() =>
	import("../../pages/shared/PUTMEApplication/putmePreview")
);

const LazyDirectEntryLogin = lazy(() =>
	import("../../pages/shared/DirectEntryApplication/directEntryLogin")
);

const LazyDirectEntryApplications = lazy(() =>
	import("../../pages/shared/DirectEntryApplication/directEntryApplication")
);

const LazyDirectEntryPreview = lazy(() =>
	import("../../pages/shared/DirectEntryApplication/directEntryPreview")
);

const LazyPartTimeApplications = lazy(() =>
	import("../../pages/shared/PartTimeApplications/partTimeApplications")
);

const LazyPartTimeLogin = lazy(() =>
	import("../../pages/shared/PartTimeApplications/partTimeLogin")
);

const LazyPartTimePreview = lazy(() =>
	import("../../pages/shared/PartTimeApplications/partTimePreview")
);

export const sharedRoutes = [
	{
		path: "/",
		component: LazyLandingPage,
		exact: true
	},
	{
		path: "/e_bursary",
		component: LazyEBursaryPage,
		exact: true
	},
	{
		path: "/e_certificate",
		component: LazyECertificate,
		exact: true
	},
	{
		path: "/login",
		component: LazyLogin,
		exact: true
	},
	{
		path: "/forgot_password",
		component: LazyForgotPassword,
		exact: true
	},
	{
		path: "/change_email",
		component: LazyChangeEmail,
		exact: true
	},
	{
		path: "/reset_password",
		component: LazyResetPassword,
		exact: true
	},
	{
		path: "/verify_otp",
		component: LazyOtpVerfication,
		exact: true
	},
	{
		path: "/impersonation_login",
		component: LazyImpersonationLogin,
		exact: true
	},
	{
		path: "/verify_account",
		component: LazyVerifyAccount,
		exact: true
	},
	{
		path: "/create_profile",
		component: LazyCreateProfile,
		router: SharedRouter,
		exact: true
	},
	{
		path: "/verify_confirmation",
		component: LazyVerifyConfirmation,
		exact: true
	},
	{
		path: "/sundry/all",
		router: SundryRouter,
		component: LazySundrySelection,
		exact: true
	},
	{
		path: "/sundry/transcript",
		router: SundryRouter,
		component: LazyTranscript,
		exact: true
	},
	{
		path: "/pg/school_fees",
		router: PGRouter,
		component: LazyPGSchoolFees,
		exact: true
	},
	{
		path: "/pg/sundry",
		router: PGRouter,
		component: LazyPGSundrySelection,
		exact: true
	},
	// {
	// 	path: "/pg/transcript",
	// 	router: PGRouter,
	// 	component: LazyTranscript,
	// 	exact: true
	// },
	{
		path: "/pg/invoice",
		component: LazyPGInvoice,
		router: SharedRouter,
		exact: true
	},
	{
		path: "/sundry_reciepts",
		component: LazySundryInvoice,
		router: SharedRouter,
		exact: true
	},
	{
		path: "/prospective_students",
		router: SharedRouter,
		component: LazyProspectiveStudents,
		exact: true
	},
	{
		path: "/admission_status_reciepts",
		router: SharedRouter,
		component: LazyAdmissionStatusInvoice,
		exact: true
	},
	{
		path: "/generate_jamb_students_invoice",
		component: LazyGenerateJambPaymentInvoice,
		router: SharedRouter,
		exact: true
	},
	{
		path: "/jamb_students_invoice",
		component: LazyJambStudentsInvoice,
		router: SharedRouter,
		exact: true
	},
	{
		path: "/generate_other_students_invoice",
		component: LazyGenerateOtherStudentsPaymentInvoice,
		router: SharedRouter,
		exact: true
	},
	{
		path: "/other_students_invoice",
		component: LazyOtherStudentsInvoice,
		router: SharedRouter,
		exact: true
	},
	{
		path: "/generate_jupeb_students_fees_invoice",
		component: LazyGenerateJupebStudentsPaymentInvoice,
		router: SharedRouter,
		exact: true
	},
	{
		path: "/jupeb_students_invoice",
		component: LazyJupebStudentsInvoice,
		router: SharedRouter,
		exact: true
	},
	{
		path: "/pg_login",
		component: LazyPGLogin,
		exact: true
	},
	{
		path: "/pg_application",
		router: SharedRouter,
		component: LazyPGApplication,
		exact: true
	},
	{
		path: "/pg_reprint_login",
		component: LazyPGReprintLogin,
		exact: true
	},
	{
		path: "/pg_application_details",
		router: SharedRouter,
		component: LazyPGApplicationDetails,
		exact: true
	},
	{
		path: "/pg_referee_form",
		router: SharedRouter,
		component: LazyPGReferenceForm,
		exact: true
	},
	{
		path: "/jupeb_login",
		component: LazyJupebLogin,
		exact: true
	},
	{
		path: "/jupeb_application",
		router: SharedRouter,
		component: LazyJupebApplication,
		exact: true
	},
	{
		path: "/jupeb_application/preview",
		router: PreviewRouter,
		component: LazyJupebPreview,
		exact: true
	},
	{
		path: "/direct_entry_login",
		component: LazyDirectEntryLogin,
		exact: true
	},
	{
		path: "/direct_entry_application",
		router: SharedRouter,
		component: LazyDirectEntryApplications,
		exact: true
	},
	{
		path: "/direct_entry_application/preview",
		router: PreviewRouter,
		component: LazyDirectEntryPreview,

		exact: true
	},
	{ path: "/putme_login", component: LazyPutmeLogin, exact: true },
	{
		path: "/putme_application",
		router: SharedRouter,
		component: LazyPutmeApplication,
		exact: true
	},
	{
		path: "/putme_application/preview",
		router: PreviewRouter,
		component: LazyPutmePreview,
		exact: true
	},
	{
		path: "/part_time_application",
		router: SharedRouter,
		component: LazyPartTimeApplications,
		exact: true
	},
	{
		path: "/part_time_login",
		component: LazyPartTimeLogin,
		exact: true
	},
	{
		path: "/part_time_application/preview",
		router: PreviewRouter,
		component: LazyPartTimePreview,
		exact: true
	}
];
