import { Link } from "react-router-dom";
import styles from "../styles.module.css";

export const ProgramCard = ({ icon, title, info }) => {
	return (
		<div className={styles.programCardContainer}>
			<div className={styles.iconBackground}>
				<img src={icon} alt="" />
			</div>
			<p className={styles.programTitle}>{title}</p>
			<p className={`mt-3 ${styles.programSubtitle}`}>{info}</p>
			<div className={`${styles.cardLinks}`}>
				<Link to="/login">Get Started</Link>
			</div>
		</div>
	);
};
